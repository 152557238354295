.about-container {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #181818;
  color: white;
}

.about-left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-left img {
  width: 100%;
  height: calc(100% - 150px);
  object-fit: contain;
  /* filter: drop-shadow(30px 10px 4px #4444dd);  */
  filter: drop-shadow(4px 8px 8px #3c4048);
}

.about-right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 60px;
}

.about-right h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #204192;
  padding-bottom: 25px;
}

.about-right p {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 20px;
  padding: 0 60px;
  line-height: 2;
}

/* Responsive */

@media screen and (max-width: 768px) {
  .about-left {
    display: none;
  }

  .about-right {
    width: 100%;
    height: 100%;
    padding: 20px 10px;
  }

  .about-right h1 {
    font-size: 2.5rem;
  }

  .about-right p {
    font-size: 0.7rem;
    padding: 0 10px;
  }
}
