.contact-container {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #181818;
  color: white;
}

.form-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.contact-left,
.contact-right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form {
  width: 450px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}

.contact-form form input:focus {
  outline: none;
  color: #204192;
}

.contact-title h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  /* padding-bottom: 20px; */
  color: #204192;
  text-align: center;
}

.contact-form form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form form input {
  height: 40px;
  margin: 10px 0;
  border: none;
  border-radius: 10px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 400;
  color: #181818;
}

.form-company-name,
.form-address,
.form-service-requested {
  width: 100%;
}
.form-company-name input,
.form-address input,
.form-service-requested input {
  width: 100%;
}

.form-names,
.form-city-state-zip,
.form-phone-email {
  display: flex;
  width: 100%;
  gap: 10px;
}

.form-names input,
.form-city-state-zip input {
  width: 50%;
}

.form-phone-email .phone-input {
  width: 30%;
}
.form-phone-email .email-input {
  width: 70%;
}

/* Button */

.contact-form form button {
  width: 100%;
  height: 40px;
  margin: 10px 0;
  border: none;
  border-radius: 10px;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 400;
  color: #181818;
  /* background-color: #22aad6; */
  background-color: #204192;
  color: white;
  cursor: pointer;
}

.contact-form form button:hover {
  background-color: #22aad6;
  transform: scale(0.99);
  transition: all 0.2s ease-in-out;
}

/* Contact Information */

.contact-right {
  display: flex;
  flex-direction: column;
}

.contact-item {
  padding: 20px;
}

.contact-item h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #204192;
}

.contact-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contact-icons svg {
  font-size: 36px;
}

/* Responsive */

@media screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    height: 100%;
    padding: 40px 0;
  }

  .form-container {
    flex-direction: column;
  }

  .contact-left,
  .contact-right {
    width: 100%;
    padding: 0 20px;
  }

  .contact-title h1 {
    font-size: 2.5rem;
    padding-bottom: 15px;
  }

  .contact-icons svg {
    font-size: 28px;
  }

  .contact-item p {
    font-size: 0.8rem;
  }
}
