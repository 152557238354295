.Navbar {
  background-color: #000;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-family: "Raleway", sans-serif; */
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 100;
}

.navbar-links_left,
.navbar-links_right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 33.33%;
}

.navbar-logo img {
  width: 100%;
  height: 100px;
}

.navbar-links_left a,
.navbar-links_right a {
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
}

.navbar-links_left a:hover,
.navbar-links_right a:hover {
  color: #22aad6;
  font-weight: 500;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
}
