.services-container {
  width: 100%;
  /* height: calc(100vh - 100px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #100f0f;
  color: white;
}

.services-title h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  padding-top: 50px;
  color: #204192;
}

.services-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* align-items: center; */
}

.services-card {
  width: 300px;
  height: 450px;
  background-color: #181818;
  color: white;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 #3c4048;
}

.services-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services-card h2 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  /* margin-top: 20px; */
  padding-top: 15px;
  color: #22aad6;
}

.services-card p {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 2;
  text-align: center;
  /* color: #204192; */
}

/* Responsive */

@media screen and (max-width: 768px) {
  .services-title h1 {
    font-size: 2.5rem;
  }

  .services-content {
    gap: 40px;
    padding: 20px 0;
  }
  .services-card {
    width: 250px;
    height: 350px;
    margin: 5px;
  }

  .services-card img {
    width: 100%;
    height: 90%;
    object-fit: cover;
  }

  .services-card h2 {
    font-size: 1rem;
    padding: 20px 0;
    margin-bottom: 0;
  }
}
