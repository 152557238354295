.home-container {
  /* height: calc(100vh - 100px); */
  height: 100vh;
  width: 100%;
}

.home-hero img {
  width: 100vw;
  /* height: calc(100vh - 100px); */
  height: 100vh;
  object-fit: cover;
}

.home-text h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 6rem;
  color: white;
  text-shadow: 0 0 10px #000000;
  font-weight: 700;
  text-align: center;
}

.home-text h3 {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 1.5rem;
  color: white;
  text-shadow: 0 0 10px #000000;
  font-weight: 700;
  text-align: center;
}

/* Responsive */

@media screen and (max-width: 768px) {
  .home-text h1 {
    font-size: 3rem;
  }
  .home-text h3 {
    font-size: 1rem;
  }
}
